import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import "../dist/css/style.css"
import ban from "../images/volunteer.png"
import vol1 from "../images/vol1.png"
import vol2 from "../images/vol2.png"
import accIcon from "../images/acc-icon.png"
const Volunteer = () => {
  return (
    <Layout>
      <SEO title="Volunteer" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img">
          <img className="w-100" src={ban} />
        </div>
        <div className="col-lg-6 px-0 bg-sec align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p className="ed-title">Volunteer with us</p>
              <p className="fi-head mb-lg-3 mb-3">
                Join our virtual classrooms
              </p>
              <p className="fi-txt  mb-lg-5 mb-4">
                To help bridge the learning loss our Students have suffered.
                32,000 children along with Teach For India Staff and Fellows
                have been committed to solving educational inequity for the past
                decade. 1000’s of volunteers have helped fuel this work.
              </p>
              <p>
                <a
                  href="https://forms.gle/71Wf8gCo4vm28NiD6"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="butn butn-main butn-sp butn-sm-sp mr-lg-2"
                >
                  Be a Volunteer
                </a>
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Container className="py-lg-5">
        <Row className="py-5">
          <div className="col-lg-4">
            <img src={vol1} className="w-100" />
          </div>
          <div className="col-lg-8 pl-4">
            <p className="fi-txt">
              Our Students have been out of school for close to two years now.
              The challenges they have faced are insurmountable and the loss of
              instructional hours has set them back years. This has been the
              result of,{" "}
            </p>
            <div>
              <p className="ed-title bolder">
                Inequitable access to devices and internet
              </p>
              <p className="ed-title bolder"> Low parental investment </p>
              <p className="ed-title bolder">
                Difficulty adapting to virtual school
              </p>
            </div>
          </div>
        </Row>
        <Row className="py-5">
          <div className="col-lg-4">
            <img src={vol2} className="w-100" />
          </div>
          <div className="col-lg-8 pl-4">
            <p className="fi-txt">
              The long-term effects of these challenges are severe. When
              combined - and if left unsolved - these can lead to
            </p>
            <div>
              <p className="ed-title bolder">
                An irreversible decline in learning
              </p>
              <p className="ed-title bolder">
                A cumulative loss of 6% in future earnings
              </p>
              <p className="ed-title bolder">
                The highest set of dropouts India has seen in three decades
              </p>
            </div>
          </div>
        </Row>
        <div className="text-center">
          <p className="ed-title">Here’s how you can support our movement</p>
          <p className="fi-head">
            Come volunteer in a virtual Teach For India classroom to bridge the
            learning loss our Students have suffered.
          </p>
        </div>
        <div className="max-60 pt-5 mx-auto max-sm-100 volAcc">
          <div className="acc-header green py-3 pr-0">
            <img src={accIcon} />
            <p className="acc-index">1</p>
            <p className="acc-header-head">Teach English or Mathematics</p>
            <p className="acc-header-title">
              and take part in extracurricular activities with a group of
              Students
            </p>
          </div>
          <div className="acc-header yellow py-3 pr-0">
            <img src={accIcon} />
            <p className="acc-index">2</p>
            <p className="acc-header-head">4 hours a week for 12 weeks </p>
            <p className="acc-header-title">(3 months)</p>
          </div>
          <div className="acc-header orange py-3 pr-0">
            <img src={accIcon} />
            <p className="acc-index">3</p>
            <p className="acc-header-head">Support a movement </p>
            <p className="acc-header-title">
              of like-minded, passionate leaders
            </p>
          </div>
          <div className="acc-header magenta py-3 pr-0">
            <img src={accIcon} />
            <p className="acc-index">4</p>
            <p className="acc-header-head">Be recognized</p>
            <p className="acc-header-title">
              and receive a certificate on completion
            </p>
          </div>
        </div>
        <div className="max-60 mx-auto max-sm-100 d-flex align-center mt-5">
          <div>
            <a
              href="https://forms.gle/71Wf8gCo4vm28NiD6"
              target="_blank"
              rel="noreferrer noopener"
              className="butn butn-main butn-sp butn-sm-sp"
            >
              Be a Volunteer
            </a>
          </div>
          <span className="max-50 pl-3">
            Applications close on July 15. Our first volunteer batch starts on
            July 19.
          </span>
        </div>
      </Container>
    </Layout>
  )
}

export default Volunteer
